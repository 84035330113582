angular.module('shared.session', [

    'satellizer'

]).service('Session', function ($q, $http, $auth, $uibModal) {

    console.log('SESSION');

    var session = {
        isAuthorized: false,
        get: get,
        login: login,
        destroy: destroy
    };

    function get() {
        console.log('get()');
        var result = $q.defer();
        if (session.isAuthorized) {
            console.log('get(): already authenticated');
            result.resolve(session);
        } else {
            console.log('get(): not authenticated yet, must get from server');
            getFromServer().then(
                function (success) {
                    console.log('get(): getFromServer() resolved', success);
                    session.isAuthorized = true;
                    session = success.data;
                    result.resolve(session);
                },
                function (error) {
                    console.log('get(): getFromServer() rejected', error);
                    result.reject(error);
                }
            );
        }
        return result.promise;
    }

    function getFromServer() {
        console.log('getFromServer()');
        var result = $q.defer();
        $http.get('/api/session').then(
            function (success) {
                console.log('getFromServer(): $http.get() resolved', success);
                session.isAuthorized = true;
                result.resolve(success);
            },
            function (error) {
                console.log('getFromServer(): $http.get() rejected', error);
                result.reject(error);
            }
        );
        return result.promise;
    }

    function login(credentials) {
        console.log('login()');
        var result = $q.defer();
        $auth.login(credentials).then(
            function (success) {
                session.isAuthorized = true;
                console.log('login(): $auth.login() resolved', success);
                result.resolve(success);
            },
            function (error) {
                console.log('login(): $auth.login() rejected', error);
                result.reject(error);
            }
        );
        return result.promise;
    }

    function destroy() {
        console.log('Session.destroy()');
        $auth.logout();
        session.isAuthorized = false;
    }

    return session;

});