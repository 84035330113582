angular.module('error.handler', [

    'ui.bootstrap',
    'error.dialog'

]).factory('$exceptionHandler', function ($injector, $log) {

    return function (exception, cause) {

        $log.error(exception);
        cause && $log.error(cause);
        var modal = $injector.get('$uibModal').open({
            animation: false,
            template: $injector.get('$templateCache').get('shared.error.dialog'),
            controller: 'ErrorDialogController',
            size: 'sm',
            resolve: {
                exception: exception,
                cause: cause
            }
        });

        modal.result.then(
            function (result) {
                //console.log('ErrorDialog returned: ', result);
            },
            function () {
                //console.log('ErrorDialog dismissed');
            }
        );

        //$delegate(exception, cause);
    };

}).config(function ($provide, $httpProvider) {

    $httpProvider.interceptors.push('errorHttpInterceptor');
    //$provide.decorator('$interpolate', 'errorInterpolateDecorator');

}).factory('errorHttpInterceptor', function ($q) {

    return {
        responseError: function responseError(rejection) {
            var segments = rejection.config.url.split('/');
            switch (rejection.status) {
                case 404:
                    if (segments.length > 1 && segments[1] == 'api') {
                        throw new Error(
                            'Не удалось загрузить данные:<br>' +
                            '<strong>Не найдено.</strong><br>' +
                            'Не мог ли другой пользователь удалить их?');
                    }
                    break;
            }
            return $q.reject(rejection);
        }
    };

}).factory('errorInterpolateDecorator', function ($q) {

    return function ($delegate) {
        var interpolateWrap = function () {
            var interpolationFn = $delegate.apply(this, arguments);
            if (interpolationFn) {
                return interpolationFnWrap(interpolationFn, arguments);
            }
        };
        var interpolationFnWrap = function (interpolationFn, interpolationArgs) {
            return function () {
                var result = interpolationFn.apply(this, arguments);
                if (result === '') {
                    console.warn("Error interpolating " + interpolationArgs[0]);
                }
                return result;
            };
        };
        angular.extend(interpolateWrap, $delegate);
        return interpolateWrap;
    }
})
;