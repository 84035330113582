angular.module('app', [

    'ui.router',
    'ui.bootstrap',
    'ncy-angular-breadcrumb',
    'ngResource',
    'ngAnimate',
    'ngStorage',
    'trusted',
    'shared',
    'public',
    'private',
    'shared.foe',
    'shared.session'

]).run(function ($rootScope, $state, $stateParams, $location, $localStorage, $templateCache, $animate, Session) {

    $rootScope.$state = $state;
    $rootScope.$stateParams = $stateParams;

    //$state = 'public.news';

    $rootScope.locales = [{
        code: 'ru',
        name: 'Русский'
    }, {
        code: 'ua',
        name: 'Українська'
    }, {
        code: 'pl',
        name: 'Polski'
    }];

    $rootScope.text = {};

    $rootScope.$on('$stateChangeStart', function (event, toState, toParams, fromState, fromParams) {
        if (toState.name == 'public.login') {
            $animate.enabled(false);
        } else {
            $animate.enabled(true);
        }
    });

    $rootScope.session = Session;

    $rootScope.previousState = $state.current;
    $rootScope.$on('$stateChangeSuccess', function(event, to, toParams, from, fromParams) {
        $rootScope.previousState = from;
        if (to) {
            var clean = $location.search('l', null).absUrl();
            $('head > link[hreflang]').each(function() {
                var href = clean;
                var hreflang = $(this).attr('hreflang');
                if (hreflang != 'x-default') {
                    href += '?l=' + hreflang;
                }
                $(this).attr('href', href)
                //console.log(hreflang, href);
            });
        }
    });

    $rootScope.$on('$stateChangeError', function (event, toState, toParams, fromState, fromParams) {
        console.warn('$stateChangeError');
        $state.go('public.login');
    });

    $rootScope.setLocale = function (code) {
        var current = undefined;
        angular.forEach($rootScope.locales, function (locale) {
            if (locale.code == code) {
                current = locale;
            }
        });
        $localStorage.locale = code;
        $('html').attr('lang', code);
        $rootScope.text.locale = current || $rootScope.locales[0];
        $rootScope.$broadcast('locale', current);
        try {
            $state.reload();
        } catch (x) {
        }
        //console.log('setLocale', $localStorage.locale);
    };

    $rootScope.initLocale = function() {
        var param = $location.search()['l'];
        var local = $localStorage.locale;
        var laravel = window.laravel.locale;
        var locale = param || local || laravel;
        $rootScope.setLocale(locale);
    }

    $rootScope.initLocale();

    $rootScope.setTitle = function () {
        var state = $rootScope.$state.current.name.split('.');
        var part = state.shift();
        if (part == 'public') {
            var name = state.pop();
            if ($rootScope.text[name]) {
                $rootScope.text.title = $rootScope.text[name].title;
            } else {
                $rootScope.text.title = undefined;
            }
        } else {
            // TODO: for private?
        }
    };

    $rootScope.$on('$stateChangeSuccess', $rootScope.setTitle);

    $templateCache.put('template/smart-table/pagination.html', $templateCache.get('shared.templates.pagination'));

    $rootScope.onlineEdit = function(element, scope) {
        var ngBindHtml = $(element).attr('ng-bind-html');
        console.warn('onlineEdit', ngBindHtml);
    }

}).factory('httpI18nInterceptor', function () {
    return {
        request: function (config) {
            //console.warn(config.method + ' ' + config.url + ' [' + $('html').attr('lang') + ']');
            config.headers['Accept-Language'] = $('html').attr('lang');
            return config;
        }
    };
}).config(function ($stateProvider, $urlRouterProvider, $locationProvider, $authProvider, $httpProvider, $sceProvider) {

    $locationProvider.html5Mode(true);

    $urlRouterProvider.otherwise('/');

    $authProvider.loginUrl = '/api/authenticate';

    $httpProvider.interceptors.push('httpI18nInterceptor');

    $sceProvider.enabled(false);

});

