angular.module('shared', [
    'error.handler',
    'templates'
]).run(function() {

}).directive('pageSelect', function () {
        return {
            restrict: 'E',
            template: '<input type="text" class="select-page" ng-model="inputPage" ng-change="selectPage(inputPage)">',
            link: function (scope, element, attrs) {
                scope.$watch('currentPage', function (c) {
                    scope.inputPage = c;
                });
            }
        }
    }
).directive('stFilter', function ($timeout, $parse) {
        return {
            restrict: 'A',
            require: '^stTable',
            scope: true,
            link: function (scope, element, attrs, ctrl) {
                var filter = $parse(attrs.stFilter)();
                scope.$watch(attrs.ngModel, function (watched) {
                    var search = ctrl.tableState().search;
                    if (!search.predicateObject) {
                        search.predicateObject = {};
                    }
                    var predicates = search.predicateObject;
                    angular.forEach(filter, function (value, column) {
                        if (!predicates[column]) {
                            predicates[column] = [];
                        }
                        var values = predicates[column];
                        var index = values.indexOf(value);
                        if (watched) {
                            if (index == -1) {
                                values.push(value);
                            }
                        } else {
                            if (index > -1) {
                                values.splice(index, 1);
                            }
                        }
                        ctrl.search(values, column);
                    });
                });
            }
        };
    }
).filter('orFilter', function ($filter) {
        return function (input, predicates) {
            var output = $filter('filter')(input, predicates, function (actual, expected) {
                if (expected instanceof Array) {
                    return expected.indexOf(actual) != -1;
                } else {
                    return actual.toString().toUpperCase().indexOf(expected.toUpperCase()) != -1;
                }
            });
            return output;
        };
    }
);
