angular.module('shared.foe', [

    'ui.router',
    'ui.tinymce'

]).directive('foe', function ($uibModal, foeCache, EditorItem) {
    return {
        restrict: 'A',
        scope: {
            foe: '='
        },
        link: link
    };

    function link(scope, element, bind) {
        var multiline = true;
        var bind = element.attr('ng-bind-html');
        var id = scope.foe;
        if (!bind) {
            multiline = false;
            bind = element.html().trim();
            if (bind.substr(0, 2) == '{{' && bind.slice(-2) == '}}') {
                bind = bind.slice(2, -2).trim();
            } else {
                console.error('foe.link(): cannot link to', element);
                return;
            }
        }
        //console.warn(bind, id);
        var resource = null;
        var components = bind.split('.');
        switch (components[0]) {
            case 'text':
                resource = 'texts';
                components.shift();
                id = components.join('.');
                break;
            case 'announce':
            case 'callback':
            case 'course':
            case 'teacher':
                resource = components[0] + 's';
                components[0] = id;
                id = components.join('.');
                break;
            default:
                console.error('foe.link(): cannot link to', element);
                return;
        }
        //console.error(bind);
        foeCache[bind + id] = {
            scope: scope.$parent,
            bind: bind,
            resource: resource,
            id: id,
            multiline: multiline
        };
        element.click(function (event) {
            if (event.ctrlKey && event.altKey) {
                event.stopPropagation();
                edit(foeCache[bind + id]);
            }
        });
        //console.log('foe.link(): linked to', element);
    }

    function edit(foe) {
        $uibModal.open({
            animation: true,
            size: 'lg',
            templateUrl: 'shared.foe',
            resolve: {
                foe: function () {
                    return foe;
                },
                item: function () {
                    return EditorItem.get({
                        resource: foe.resource,
                        id: foe.id
                    });
                }
            },
            controller: 'FoeDialogController',
        });
    }

}).value('foeCache', {}).controller('FoeDialogController', function ($scope, $state, $uibModalInstance, $timeout, EditorItem, foe, item) {

    console.log('FoeDialogController');

    var tinymce_locale = {
        ru: 'ru',
        ua: 'uk',
        pl: 'pl'
    };
    $scope.show = {
        ru: true,
        ua: true,
        pl: true,
        multiline: foe.multiline,
        tinymce: {
            inline: true,
            fixed_toolbar_container: '#tinymce-toolbar-container',
            //language_url: '/js/tinymce-i18n-ru.js',
            plugins: [
                "advlist autolink lists link image charmap print preview hr anchor pagebreak",
                "searchreplace wordcount visualblocks visualchars code fullscreen",
                "insertdatetime media nonbreaking save table contextmenu directionality",
                "emoticons template paste textcolor colorpicker textpattern"
            ],
            toolbar: "insertfile undo redo | styleselect | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | link image media",
            file_browser_callback : function(field_name, url, type, win) {
                var roxyFileman = '/fileman/index.html';
                if (roxyFileman.indexOf("?") < 0) {
                    roxyFileman += "?type=" + type;
                }
                else {
                    roxyFileman += "&type=" + type;
                }
                roxyFileman += '&input=' + field_name + '&value=' + win.document.getElementById(field_name).value;
                if(tinyMCE.activeEditor.settings.language){
                    roxyFileman += '&langCode=' + tinyMCE.activeEditor.settings.language;
                }
                tinyMCE.activeEditor.windowManager.open({
                    file: roxyFileman,
                    title: 'Roxy Fileman',
                    width: 850,
                    height: 650,
                    resizable: "yes",
                    plugins: "media",
                    inline: "yes",
                    close_previous: "no"
                }, {     window: win,     input: field_name    });
                return false;
            },
            setup: function (editor) {
                editor.on('init', function (event) {
                    if (event.target.targetElm.getAttribute('locale') == $scope.text.locale.code) {
                        console.log('init()', event.target.targetElm.getAttribute('locale'), event.target.targetElm.getAttribute('id'));
                        //$timeout(function(){ editor.focus(); });
                        $timeout(function () {
                            //event.target.focus();
                            $(event.target.targetElm).trigger('focus');
                        });
                    }
                });
                editor.on('focus', function (event) {
                    console.log(event.target.id, 'in focus now');
                    $scope.show.current = event.target.targetElm.getAttribute('locale');
                    //event.target.execCommand('mceInsertRawHTML', false, '');
                    //$()
                    //tinyMCE.execCommand("mceRepaint");
                    $timeout(function () {
                        var e = jQuery.Event('keydown');
                        e.keyCode = 50;
                        //$(event.target.targetElm).trigger(e);
                        //$(event.target.targetElm).addClass('mce-edit-focus');
                        //$('#tinymce-toolbar-container').css('min-height', '73px');
                        //$('#tinymce-toolbar-container .mce-panel')
                        //    .css('height', '71px')
                        //    .css('display', 'block')
                        //    .attr('hidefocus', '0')
                        //    .show();
                    });
                });
                editor.on('blur', function () {
                    return false;
                });

            },
        }
    };
    $scope.item = item;
    $scope.item.resource = foe.resource;
    $scope.item.id = foe.id;
    $scope.save = save;
    $scope.cancel = cancel;

    load();

    //function getContent(scope, bind) {
    //    //console.log('getContent', scope, bind, scope.$eval(bind));
    //    return scope.$eval(bind);
    //}
    //
    function setContent(scope, bind, content) {
        //console.log('setContent', scope, bind, content);
        //content = content.split('"').join("'");
        scope.$eval(bind + '=\'' + content + '\'');
    }

    function load() {
        //console.log($scope.item);
        $scope.item.$promise.then(
            function (success) {
                $scope.item.resource = foe.resource;
                $scope.item.id = foe.id;
                //console.log($scope.item);
            },
            function (error) {
                console.log(error);
                //$scope.show.error = error;
            }
        );
    }

    function save() {
        //console.log('save()', $scope.item, $scope.text.locale);
        //$scope.item.resource = foe.resource;
        //$scope.item.id = foe.id;
        EditorItem.save($scope.item).$promise.then(
            function (success) {
                console.log('EditorItem.save() resolved', foe.resource);
                $uibModalInstance.close();
                if (foe.resource == 'texts') {
                    setContent(foe.scope, foe.bind, $scope.item[$scope.text.locale.code]);
                } else {
                    $state.reload();
                }
            },
            function (error) {
                //console.log('EditorItem.save()', error);
                $scope.show.error = error.data.error;
            }
        );
    };

    function cancel() {
        $uibModalInstance.dismiss();
    }
    $uibModalInstance.result.then(
        function (result) {
            console.log('mceRemoveEditor result');
        },
        function (dismiss) {
            console.log('mceRemoveControl dis');
            $('[ui-tinymce]').each(function(index, element) {
                console.log('mceRemoveControl', true, element.id);
                //tinymce.EditorManager.execCommand('mceRemoveEditor', true, element.id);
            });
        }
    );

}).factory('EditorItem', function ($resource) {
    return $resource('/api/editor/:resource/:id', {resource: '@resource', id: '@id'},
        {save: {method: 'PUT'}});
    //{
    //    get: function (id) {
    //        return $http.get('/api/editor/items/' + id).$promise;
    //    },
    //    save: function(id, item) {
    //        return $http.post('/api/editor/items/' + id, item).$promise;
    //    }
    //};
});