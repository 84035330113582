angular.module('error.dialog', [
    'ui.bootstrap'
]).controller('ErrorDialogController', function ($scope, $uibModalInstance, exception, cause) {

    $scope.exception = exception;
    $scope.cause = cause;

    $scope.ok = function () {
        $uibModalInstance.close('ok');
    };

    $scope.cancel = function () {
        $uibModalInstance.dismiss('cancel');
    };

})
;
