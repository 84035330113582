(function () {

    var active = false;

    document.addEventListener('keydown', function (event) {
        event = event || window.event;
        if (event.ctrlKey && event.altKey) {
            //console.log('keydown - gotcha!', event.target);
            $(document.body).addClass('foe');
            active = true;
            event.stopPropagation();
        }
    }, true);

    document.addEventListener('keyup', function (event) {
        if (active) {
            active = false;
            $(document.body).removeClass('foe');
            event.stopPropagation();
            //console.log('keyup - gotcha!', event.target);
        }
    }, true);

})();

